import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureListing } from '../../../util/data';
import { IconPin } from '../../../components';

import css from './SearchMapPriceLabel.module.css';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      isActive,
      onListingClicked,
      setMapPinList,
    } = this.props;
    const currentListing = ensureListing(listing);

    const classes = classNames(rootClassName || css.root, className);
    const pinClasses = classNames(css.pinIcon, {
      [css.pinIconActive]: isActive,
    });

    return (
      <button
        className={classes}
        onClick={() => onListingClicked(currentListing)}
        onMouseEnter={list => {
          setMapPinList(currentListing.id);
        }}
        onMouseLeave={() => {
          setMapPinList(null);
        }}
      >
        <IconPin className={pinClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
  setMapPinList: null,
};

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  setMapPinList: func,
};

export default injectIntl(SearchMapPriceLabel);
