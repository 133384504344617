import React, { Component } from 'react';
import { func, object, number, shape, string, arrayOf } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, useIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { createResourceLocatorString } from '../../../util/routes';

import { Modal, Button } from '../../../components';

import css from './SearchAdditionalFilters.module.css';

class SearchAdditionalFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, routeConfiguration, type } = this.props;

    onCloseModal();
    this.setState({ initialQueryParams: null });
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {
          type,
        },
        this.state.initialQueryParams
      )
    );
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      resultsCount,
      onManageDisableScrolling,
      isOpen,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const filtersHeading = intl.formatMessage({ id: 'SearchAdditionalFilters.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchAdditionalFilters.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchAdditionalFilters.showListings' },
      { count: resultsCount }
    );

    return (
      <div className={classes}>
        <Modal
          id="SearchAdditionalFilters.filters"
          isOpen={isOpen}
          onClose={this.cancelFilters}
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={modalCloseButtonMessage}
          usePortal
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <button className={css.resetAllButton} onClick={e => this.resetAll(e)}>
              <FormattedMessage id={'SearchAdditionalFilters.resetAll'} />
            </button>
          </div>
          {isOpen ? <div className={css.filtersWrapper}>{children}</div> : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

SearchAdditionalFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  selectedFiltersCount: 0,
};

SearchAdditionalFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  resultsCount: number,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchAdditionalFilters = props => {
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();

  return (
    <SearchAdditionalFiltersComponent
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      {...props}
    />
  );
};

export default SearchAdditionalFilters;
