import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {
  ensureListing,
  ensureUser,
  ensureCompanySearchOrUserType,
  userDisplayNameAsString,
  removeLastInitial,
} from '../../../util/data';

import { AspectRatioWrapper, ResponsiveImage } from '../../../components';

import css from './SearchMapInfoCard.module.css';

const TALENT_ASPECT_RATIO_WIDTH = 4;
const TALENT_ASPECT_RATIO_HEIGHT = 5;

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing, config } = props;

  const { title, publicData } = listing.attributes;

  const authorAvailable = listing && listing.author;
  const currentAuthor = authorAvailable ? listing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const isCompanyUser = ensureCompanySearchOrUserType(publicData?.type);
  const listingTitle = isCompanyUser ? title : removeLastInitial(authorDisplayName);

  const firstImage = isCompanyUser
    ? listing?.images?.length > 0
      ? listing.images[0]
      : null
    : listing?.author?.profileImage
    ? listing.author.profileImage
    : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const imageAspectWidth = isCompanyUser ? aspectWidth : TALENT_ASPECT_RATIO_WIDTH;
  const imageAspectHeight = isCompanyUser ? aspectHeight : TALENT_ASPECT_RATIO_HEIGHT;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  return (
    <a
      alt={listingTitle}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
    >
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [css.borderRadiusInheritBottom]: !isInCarousel,
        })}
      >
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={imageAspectWidth}
          height={imageAspectHeight}
        >
          <ResponsiveImage
            rootClassName={classNames(css.rootForImage, css.borderRadiusInheritTop)}
            alt={listingTitle}
            noImageMessage={intl.formatMessage({ id: 'SearchMapInfoCard.noImage' })}
            image={firstImage}
            variants={variants}
            sizes="150px"
          />
        </AspectRatioWrapper>
        <div className={classNames(css.info, { [css.borderRadiusInheritBottom]: !isInCarousel })}>
          <div className={css.name}>{listingTitle}</div>
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [listingIndex, setlistingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;
  const listing = ensureListing(listings[listingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithCarousel]: hasCarousel,
  });
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(listing)}
        listing={listing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setlistingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>{`${listingIndex + 1}/${listings.length}`}</div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setlistingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
