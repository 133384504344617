import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { SecondaryButton, IconMap } from '../../components';

import classNames from 'classnames';

import css from './SearchPage.module.css';

const ToggleMapButton = props => {
  const { isMapOpen, hasNoResult, onCloseMap, onOpenMap } = props;

  if (isMapOpen) {
    return (
      <SecondaryButton className={css.closeMapButton} onClick={onCloseMap}>
        <FormattedMessage id="SearchPage.closeMap" />
        <span className={css.closeMapIcon}>
          <FormattedMessage id="SearchPage.closeMapSymbol" />
        </span>
      </SecondaryButton>
    );
  }

  return (
    <SecondaryButton
      className={classNames(css.openMapButton, {
        [css.openMapButtonNoResults]: hasNoResult,
      })}
      onClick={onOpenMap}
    >
      <FormattedMessage id="SearchPage.openMap" />
      <IconMap className={css.openMapButtonIcon} />
    </SecondaryButton>
  );
};

ToggleMapButton.defaultProps = {
  isMapOpen: true,
  hasNoResult: false,
  onCloseMap: null,
  onOpenMap: null,
};

ToggleMapButton.propTypes = {
  isMapOpen: bool.isRequired,
  hasNoResult: bool.isRequired,
  onCloseMap: func.isRequired,
  onOpenMap: func.isRequired,
};

export default ToggleMapButton;
